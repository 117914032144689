@import "variables.scss";

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
  margin-left: 5px;
}

.react-datepicker {
  font-family: $datepicker__font-family;
  font-size: $datepicker__font-size;
  background-color: #fff;
  color: $datepicker__text-color;
  border: 1px solid $datepicker__border-color;
  display: inline-block;
  position: relative;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__header {
  text-align: center;
  background-color: $datepicker__header-color;
  padding: 5px 0 0px;
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.react-datepicker__header:first-child {
  margin: 0 !important;
}

.react-datepicker__month-container {
  float: left;
  border: 1px solid #d7d7d7;
  border-radius: 0.4375rem;
}

.react-datepicker__month {
  text-align: center;

  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}

.react-datepicker__aria-live {
  display: none !important;
  visibility: hidden !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  
}

.react-datepicker__day-names {
  color: #009aa6 !important;
  font-weight: 400;
  text-align: center;
  font-style: italic;
  text-transform: capitalize;
  padding-bottom: 4px;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__month,
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;

  &:hover {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__background-color;
  }

  &--highlighted {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__highlighted-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__highlighted-color, 5%);
    }

    &-custom-1 {
      color: magenta;
    }

    &-custom-2 {
      color: green;
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--keyboard-selected {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
  &.react-datepicker__month--selected,
  &.react-datepicker__month--in-range,
  &.react-datepicker__quarter--selected,
  &.react-datepicker__quarter--in-range {
    &:hover {
      background-color: $datepicker__selected-color;
    }
  }

  &:hover {
    background-color: $datepicker__background-color;
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;

  span {
    display: none !important;
  }
}

.arrow-btn {
  min-width: 25px !important;
  font-weight: 900 !important;
  font-size: 1rem !important;
  color: #0088ce !important;
  height: 25px;
}

select.select-datepicker {
  color: #0088ce;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23333;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-down%3C/title%3E%3Cpath class='a' d='M25,40.42,1.15,17a4.69,4.69,0,0,1,0-6.15A5.15,5.15,0,0,1,4.23,9.65a4.38,4.38,0,0,1,3.08,1.16L25,28.12,42.69,10.81a4.6,4.6,0,0,1,6.93.77,5.39,5.39,0,0,1-1.16,5.77Z'/%3E%3C/svg%3E"),linear-gradient(to left, #fff 3.125rem, #FFF 3.125rem)!important;
  padding: 0.2rem 2rem 0.2rem 1rem !important;
  background-repeat: no-repeat;
  background-size: 0.5125rem,100%;
  background-position: center right 1.1875rem,top left;
  border: 1px solid #333;
  min-height: 25px !important;
  width: auto !important;

}

.select-datepicker:focus {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23333;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-up%3C/title%3E%3Cpath class='a' d='M25,9.58,48.85,33a4.69,4.69,0,0,1,0,6.15,5.15,5.15,0,0,1-3.08,1.16,4.38,4.38,0,0,1-3.08-1.16L25,21.88,7.31,39.19a4.6,4.6,0,0,1-6.93-.77,5.39,5.39,0,0,1,1.16-5.77Z'/%3E%3C/svg%3E"),linear-gradient(to left, #fff 3.125rem, #fff 3.125rem) !important;
  border: 1px solid #333;
}

.picker-input {
  .MuiOutlinedInput-input {
    text-align: center;
  }
}
