@import "../node_modules/bootstrap/scss/bootstrap";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  
.welcome-container {
  background-color: #242830;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 40px;
  font-size: 12px;
  @include media-breakpoint-up(lg) {
    font-size: 14px;
  }

  width: 20%;
}

.home-container {
  height: calc(100vh - var(--topbar-height));

  display: flex;
  justify-content: space-between;
  width: 100%;

  .home-image {
    height: 100%;
    object-fit: cover;
    margin: auto;
    padding: 20px 0px;

    .main-image {
      height: 100%;
      width: 100%;
      margin: auto;
    }
  }
}


.header-links {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #fff;
  color: inherit;
  border-radius: 5px;
  padding: 1rem;
  max-width: 10rem;
  min-width: 5rem;
  width: 100%;
}

.header-content {
  margin-top: -10rem;
  width: 40%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  min-height: 15rem;
}

.question-circle {
  color: var(--color-yellow-500)
}


.book {
  color: var(--color-green-900)
}

.home-body {
  padding: 2rem;
  height: calc(100vh - 72px - 20vh);
  
  div {
    margin-left: auto;
    margin-right: auto;
  }// 72px is the size of the navbar 20vh size of the banner
}

.aside-text {
  max-width: 20rem;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.aside-block {
  padding: 1rem;
  min-width: 20%;
}

.main-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

main {
  background-color: #fff;
}