.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;

  svg {
    font-size: 12px;
    margin-bottom: 2px;
    margin-right: 2px;
  }
}