.arrayComponent {
  width: 100%;
  max-width: 100%;
  padding: 5px;
  padding-top: 0px;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;

  .arrayComponent-header {
    z-index: 10;
    color: #72767A;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 10px;

    .arrayComponent-header-first {
      display: flex;
      flex-direction: row;
      width: 15%;
      max-width: 15%;
      background: white;
      .arrayComponent-header-col1 {
        height: 100%;
      }
    }
    
    .arrayComponent-header-second {
      display: flex;
      flex-direction: row;
      flex: 1;
      background: white;

      .arrayComponent-header-col4 {
        width: 20%;
      }
      .arrayComponent-header-col5, .arrayComponent-header-col6  {
        width: 40%;
      }
    }
  }

  .arrayComponent-body {
    padding-top: 20px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .arrayComponent-body-container {
      display: flex;
      flex-direction: column;

      .arrayComponent-separate {
        border-bottom: solid 1px rgba(128, 128, 128, 0.45);
      }
    }
    .arrayComponent-body-section {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 5px;


      .arrayComponent-body-section-first {
        align-self: flex-start;
        display: flex;
        flex-direction: row;
        width: 15%;
        position: sticky;
        top: 40px;

        .arrayComponent-body-col1 {
          display: flex;
          flex-direction: row;

          .arrayComponent-body-col1-readOnly {
            margin-top: 20px;
            font-size: 12px;
            font-style: italic;
          }
    
          .arrayComponent-body-col1-number {
            font-size: 25px;
            padding: 4px 5px 0px 5px;
            height: 40px;
            margin-right: 10px;
            font-weight: bold;
            border-radius: 5px;
            background: #F1F6F9;
          }
    
          .arrayComponent-body-col1-libelle {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            span {
              margin-bottom: 10px;
              font-weight: bold;
            }
    
            a {
              color: #656B77;
              text-decoration: underline;
            }
            a:hover {
              color: #1B9EE8;
            }
    
          }
        }
      }

      .arrayComponent-body-section-second-container {
          display: flex;
          flex-direction: column;
          flex: 1;

        .arrayComponent-body-section-second-noProposal {
          margin-bottom: 20px;
          width: 100%;
          text-align: center;
        }
        .arrayComponent-body-section-second {
          padding: 10px 0;
          display: flex;
          flex-direction: row;
          width: 100%;
          border-radius: 5px;

          &:nth-child(odd) {
            background: #F2F2F2;
          }

          .arrayComponent-body-col4 {
            max-width: 20%;
            width: 20%;
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            padding: 0 10px;

            .arrayComponent-body-col4-first {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              justify-content: flex-start;
              .arrayComponent-body-col4-cotation {
                text-align: center;
                border-radius: 5px;
                height: 30px;
                width: 30px;
                color: white;
                font-weight: bold;
                background: #1B9EE8;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .arrayComponent-body-col4-date {
                margin-left: 10px;
                font-weight: 500;
              }
            }
    
            .arrayComponent-body-col4-username {
              display: flex;
              flex-direction: column;
      
              .arrayComponent-body-col4-name {
                font-weight: bold;
                display: flex;
                gap: 10px;

                  .axe-icon {
                    height: 20px;
                    width: 20px;
                  }
              }
              .arrayComponent-body-col4-function {
                font-style: italic;
              }
            }
          }
          .arrayComponent-body-col5 {
            max-width: 40%;
            width: 40%;
            padding-right: 10px;
            font-size: 15px;
            white-space: pre-line;

            .arrayComponent-body-col5-file {

              margin-top: 10px;

              .paperclip {
                margin-top: 5px;
              }
              .see-file {
                font-size: 12px;
                margin-left: 5px;
                border: none;
                padding: 5px;
                border-radius: 8px;
                background: #F2F2F2;
                
                &:hover {
                  background: #838383;
                  color: #fff
                }
              }
            }
          }
          .arrayComponent-body-col6 {
            max-width: 40%;
            width: 40%;
            font-size: 15px;
            white-space: pre-line;
          }
        }
      }
    }
  }
}