.cotation-picker {

  .rating {
    width: 40px;
    text-align: center;
    padding: 9px 7px 7px 7px;
    border-radius: 10px;
    color: var(--color-white);
    margin-left: 1rem;
    border: none;
  }
}