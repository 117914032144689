.autocomplete-obj {
  background-color: #fff !important;
  padding: 0 !important;
  height: 100% !important;
  .MuiOutlinedInput-root {
    padding: 0 !important;
    border: 1px solid #f3f3f3 !important;
  border-radius: 4px !important;
    .MuiAutocomplete-input {
      font-size: 12px;
      font-weight: 500;
      padding-left: 1.25rem !important;
      width: 100% !important;
    }
  }
  .MuiChip-root {
    border-radius: 4px !important;
  }
}

.obj-list{
  width: calc(100% - 70px);
  max-height: 215px;
  overflow-y: auto;
}

.obj-chip {

  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-size: 0.8125rem;
  height: 32px;
  margin: 3px;
  align-items: center;

  .delete-icon {
    -webkit-tap-highlight-color: transparent;
    color: rgba(0, 0, 0, 0.26);
    font-size: 22px;
    cursor: pointer;
    margin: 0 5px 0 5px;
  }

  .obj-label {
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    padding-left: 5px;
    text-overflow: ellipsis;
  }
}

.highlight {
  animation: highlight 1s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

@keyframes highlight {
  0% {
    background-color: #00000060;
    color: white;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.08);
  }
}