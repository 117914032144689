.zp-cotation {
  height: calc(80vh - var(--header-height));
  max-height: calc(80vh - var(--header-height));
  background-color: white;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .head {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 54px;
    position: relative;
    background-color: white;
    z-index: 5;

    h2 {
      font-size: 20px;
      font-weight: 800;
    }

    .selects {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 20px !important;
    color: #747678 !important;
    box-shadow: 0 0 3px 2px #e1e1e1;
    width: 15rem;
  }
  .Mui-focused {
      border-color: #bdbdbd !important;
      border: none !important;
      outline: none !important;
  }
  .MuiButton-root {
      margin-left: 0.5rem;
      text-transform: none !important;
      background-color: #747678!important;
      color: #fff !important;
      border-radius: 20px !important;
  }
  .MuiButton-root:focus {
      outline: none !important;
  }

  .select-wrapper {
    display: flex;
    gap: 5px;
    width: 30px;
    text-overflow: ellipsis;
  }

  .wrapper-table {
    padding: 0px 10px 10px 10px;
    height: 70vh;
    width: calc(100vw - 100px);
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}