.popper-container {
  background-color: #F3F3F3;

  padding: 10px 0px;
  border-radius: 10px;
  
  .title {
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;

    span {
      font-weight: bold;
      font-size: 18px;
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .content {
    padding: 0px 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
    max-height: 170px;
    overflow: auto;

    .author {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .animator {
        display: flex;
        align-items: center;
        gap: 5px;

        .avatar {
          font-size: 16px;
          height: 35px;
          min-height: 35px;
          min-width: 35px;
          width: 35px;
          background: var(--color-blue-700);
          color: #fff;
          border-radius: 50%;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
  
          span {
            padding-top: 2px;
          }
        }

        span {
          font-weight: 700;
          font-size: 14px;
        }
  
        p {
          font-size: 14px;
          margin-bottom: 0;
          text-align: right;
        }
      }
    }

    .date {
      display: flex;
      flex-direction: column;

      span:first-child {
        font-weight: 700;
        font-size: 14px;
        text-align: right;
      }

      span:last-child {
        font-size: 14px;
        text-align: right;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      gap: 5px;

      span {
        font-weight: 700;
        font-size: 12px;
      }

      p {
        font-size: 14px;
        margin-bottom: 0;
        white-space: pre-line;
      }
    }
  }
}