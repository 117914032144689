.display-toggle {

  .display-toggle-options-control {
    align-items: center;
    height: 100%;
    display: inline-flex;
    padding: .3125rem;
    background-color: #4d4f53;
    border-radius: .6875rem;

    > label {
      margin: 0;
      padding: .375rem 1.25rem .3125rem;
      color: #fff;
      white-space: nowrap;
      cursor: pointer;
      border-radius: .4375rem;
      font-size: 14px;

      &.checked {
        background-color: #fff;
        color: #4d4f53;
      }
    }
  }
}