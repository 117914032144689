.composantTodoEdit-modal{
  overflow: auto;
}

.composantTodoEdit {
  position: absolute;
  width: 1150px;
  margin: 10vh calc((100vw - 1150px)/2);
  min-width: 1150px;
  min-height: 80vh;
  background-color: #fff;
  box-shadow: 24px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }

  .composantTodoEdit-title {
    font-size: 25px;
    font-weight: 900;
    margin-top: 10px;
    margin-left: 20px;
  }
  .composantTodoEdit-body {
    padding-left: 35px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .composantTodoEdit-body-composant {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 10px 20px;
      border-radius: 10px;
      min-width: 245px;
      max-width: 245px;
      max-height: 150px;
      min-height: 150px;
      border: 2px solid var(--color-lighter-grey);
      justify-content: space-between;

      &:hover {
        background-color: var(--color-lighter-grey);
        cursor: pointer;
      }

      .composantTodoEdit-body-composant-title {
        padding-top: 5px;
        font-size: 12px;
      }

      .composantTodoEdit-body-composant-info {
        display: flex;
        font-style: italic;
        font-size: 11px;
        font-weight: bold;
        align-items: end;
      }

      .composantTodoEdit-body-composant-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .composantTodoEdit-body-composant-header-id {
          font-size: 25px;
          font-weight: bold;
          margin-right: 150px;
        }
        .composantTodoEdit-body-composant-header-cotation {
          margin-top: 5px;
          .composantTodoEdit-body-composant-header-cotation-item {
            text-align: center;
            min-width: 30px;
            width: 30px;
            min-height: 30px;
            height: 30px;
            font-size: 15px;
            font-weight: 900;
            border-radius: 5px;
            padding: 5px;
            color: #fff;
          }

        }
      }
    }
  }

  .composantTodoEdit-button-confirmation {
    text-align: end;
  }
}