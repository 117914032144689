.action-item {
  background-color: #FFFFFF;
  padding: 15px 10px 10px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  margin-bottom: 10px;
  border: none;

  .action-item-id {
    display: flex;

    .action-item-id-item {
      height: 40px;
      > span {
        text-align: center;
        padding: 4px;
        font-size: 25px;
        min-width: 25px;
        margin-right: 10px;
        font-weight: bold;
        border-radius: 5px;
        background: #F1F6F9;
      }
      
    }

    .action-item-libelle {
      height: 38px;
  
        > p {
          margin-top: 5px;
          font-size: 14px;
          color: #242830;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: start;
        }
    }
  }
  .action-item-sa {
    height: 40px;

    .action-item-jalon {
      color: #242830;
      font-weight: 800;
      margin-top: 5px;
      font-size: 17px;
    }

    .action-item-date {
      color: #A4A4A4;
      margin-top: 5px;
      font-size: 17px;
    }
  }

  .action-item-cotation {
    color: #FFFFFF;
    
    .action-item-cotation-item {
      text-align: center;
      min-width: 30px;
      width: 30px;
      min-height: 30px;
      height: 30px;
      font-size: 15px;
      font-weight: 900;
      border-radius: 5px;
      padding: 5px;
    }
  }
}