
.weather-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &.rainy {
    background: rgb(127, 143, 166);
  }

  &.sunny {
    background: rgb(255, 204, 0);
  }

  &.cloudy {
    background: rgb(0, 136, 206);
  }

  &.none {
    background: white;
  }
}