.divider {
  margin: auto !important;
}

.list-item {
  list-style: none;
  border: 1px solid var(--color-light-grey);
  &:not(:last-child) {
    border-bottom: 0,
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  .new {
    font-weight: 700;
  }

  .new-edit {
    color: var(--color-red-600);

    .new-close {
      border: none;
      padding: 0;
      margin-left: 10px;
      color: var(--color-red-600);
    }
  }
}

.no-composant-msg {
  color: #ffb612 !important;
  font-size: 12px !important;
}

.no-expand {
  margin-top: 0 !important;
}

.search {
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    margin-right: 8px;
  }
  .MuiTypography-root {
    align-self: center;
  }
}