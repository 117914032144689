.modal-form {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  .modal-form-title {
    font-size: 28px;
    color: #4d4f53;
  }

  .modal-form-form {
    display: flex;
    flex-direction: column;
    padding: 5px;

    .modal-form-form-name {
      width: 100%;
      margin-top: 10px;
      background-color: var(--color-lighter-grey);
      border-radius: 10px;

      div {
        border-radius: 10px;
        background-color: var(--color-lighter-grey);
      }
    }

    .modal-form-form-entity {
      margin-top: 20px;
      background-color: var(--color-lighter-grey);
      border-radius: 10px;

      div {
        border-radius: 10px;
        background-color: var(--color-lighter-grey);
      }
    }

    .modal-form-form-dates {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      

      .modal-form-form-dates-date {
        input {
          padding: 10px;
        }
        fieldset {
          border-radius: 10px;
          background-color: (--color-lighter-grey);
          top: 0;
        }

        .react-datepicker-wrapper{
          .react-datepicker__input-container {
            div {
              border-radius: 10px;
              background-color: var(--color-lighter-grey);
            }
          }
        }
      }

      .modal-form-form-dates-open {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 3px;

      }
      .modal-form-form-dates-close {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 3px;
      }
      .modal-form-form-dates-span {
        padding-left: 5px;
        font-weight: 900;
      }
    }

    .modal-form-form-description {
      margin-top: 20px;
      background-color: var(--color-lighter-grey);
      border-radius: 10px;
      padding: 10px;
    }

    .modal-form-form-acteurs {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .modal-form-form-acteurs-pilote {
        width: 45%;
        border-radius: 10px;

        div {
          border-radius: 10px;
          background-color: var(--color-lighter-grey);
        }

        .info {
          font-size: 12px;
          background-color: #ffffff;
          border: none;
          min-height: 17px;
          color: var(--color-red-600);
        }
      }
    }

    .modal-form-form-checkbox {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .modal-form-buttons {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
  }
}

.react-datepicker-popper {
  z-index: 9999;
}