.action-panel {
  flex: 1;
  min-width: 24%;
  background-color: #F3F3F3;
  margin-right: 18px;
  border-radius: 10px;
  padding: 10px;

  &.collapsed {
    max-width: 40px;
    min-width: 40px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .action-panel-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: 10px;

    &.collapsed {
      padding: 0;
      margin: 0;
      writing-mode: vertical-lr;
    }

    .collapse-button {
      cursor: pointer;
      width: 16px;
      height: 16px;
      padding: 5px 0;
    }

    .action-panel-title-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .action-panel-title-icon {
      font-size: 20px;
    }


    .action-panel-title-libelle {
      display: flex;
      margin: auto 0;
      color: #ADADAD;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 900;
    }
  }

  .action-panel-content {
    overflow-y: auto;
    max-height: calc(100% - 46px);
  }
}