.custom-text-area {
  width: 100%;
  border-color: #ADADAD;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;

  &:focus-visible {
    outline: #ADADAD
  }
}