.filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 25px;
  gap: 50px;

  .item {
    display: flex;
    align-items: center;
    vertical-align: middle;
    gap: 10px;

    .checkbox {
      padding-top: 10px;

      span {
        font-size: 13px;
      }
    }

    .ordering {
      display: flex;
      align-items: center;
      gap: 5px;
      div {
        color: #868686;
        font-size: 13px;
      }
      button {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0;
        border: none;
        background: transparent;
        color: #4d4f53;

        &:focus {
          outline: none;
        }

        .icon {
          font-size: 14px;
        }
      }
    }
  }
  .cotations {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    min-height: 34px;
  }
  
  .cotation-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 140px;
  
    .selected {
      scale: 1.2;
    }
  
    .gravity {
      background-color: black;
    }
  
    button {
      border: none;
      padding-top: 2px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      font-weight: 800;
      border-radius: 3px;
      min-width: 27px;
      background-color: #C6C6C6;
  
      &:focus {
        outline: none;
      }
  
      span {
        text-align: center;
      };
    }
  }
}
