:root {
  // colors
  --color-carbone: #242830;
  --color-carbone-300: #797979;
  --color-carbone-500: #545454;

  --color-white: #FFFFFF;
  --color-near-white: #F5F6F6;
  --color-lighter-grey: #EEEEEE;
  --color-light-grey: #C6C6C6;
  --color-medium-grey: #838383;
  --color-near-black: #272727;
  --color-grey-900: #3C3C3B;
  --color-grey-800: #575F6F;
  --color-grey-500: #919191;

  --color-grey-300: #C8C8C8;
  --color-grey-200: #E3E3E3;
  --color-grey-100: #E2E7EF;
  
  --color-blue-500: #41A8F9;
  --color-blue-600: #1294F8;
  --color-blue-700: #0673C6;
  --color-blue-900: #022E4F;
  --color-light-blue: #F1F8FF;
  --color-medium-blue: #546EFE;

  --color-yellow-500: #FECC00;
  --color-light-yellow: #FDF2D0;

  --color-delete: #FF4242;

  --color-red-400: #FD7B62;
  --color-red-500: #FC5130;     
  --color-red-600: #E83F3F;

  --color-green-500: #04E762;
  --color-green-900: #09C959;

  --color-warning-900: #EE9207;

  --color-purple-500: #832667;

  --color-personal-token: #F7EAF9;
  --color-external-token: #DEF7DE;
  --color-service-token: #F6F5C5;

  --color-valid-token: #31C752;
  --color-pending-token: #EE9207;
  --color-rejected-token: #EB604D;
  // sizes
  --topbar-height: 72px;
  --userbar-height: 68px;
  --tabsbar-height: 48px;
}