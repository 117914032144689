.modal-create {
  .custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    max-height: 95vh;
    overflow-y: auto;
    width: calc(100vw - 48px);
    padding: 0;
    min-width: 300px;
    max-width: 1300px;
  }

  .create {
    display: flex;
    width: 100%;
    gap: 20px;

    .modal-form {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 20px;
      gap: 20px;

      .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all .5s ease;

        span {
          font-size: 20px;
          font-weight: 600;
        }

        button {
          display: flex;
          align-items: center;
          border: none;
          background-color: transparent;
          border-radius: 50%;
          padding: 3px;

          &:hover {
            background-color: #ececec;
          }
        }
      }

      .portfolio-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
  
        .vertical-display {
          div {
            input {
              width: 100% !important;
            }
          }
        }
      }

      .button {
        display: flex;
        width: 100%;
        justify-content: center;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          width: 300px;
          color: white;
          padding: 7px 20px 6px 20px;
          border-radius: 23px;
          font-weight: 500;
          font-size: 14px;
          transition: all .5s ease;
          background-color: #0aa8f9;
          background-image: linear-gradient(to right,rgba(255,255,255,0),#2a65a6 100%);

          &:hover {
            box-shadow: none;
            background-color: #2a65a6;
          }

          &:focus {
            box-shadow: none;
            outline: none;
          }

          &:disabled {
            background-color: #d3d3d3;
            background-image: linear-gradient(to right,rgba(255,255,255,0),#d3d3d3 100%);
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.modal-delete-wrapper {

  .custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    max-height: 95vh;
    overflow-y: auto;
    width: 450px;
    padding: 0;
    padding: 20px;
    min-width: 300px;
    max-width: 600px;
  }

  .modal-delete {
    display: flex;
    flex-direction: column;
    gap: 35px;

    .title {
      display: flex;
      gap: 10px;

      .icon {
        .item {
          font-size: 40px;
          color: #FC5130;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 18px;

        .libelle {
          font-weight: 600;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      .cancel {
        width: 150px;
        border: solid 1px var(--color-grey-900);
        background-color: transparent;
        padding: 7px 30px;
        border-radius: 20px;
        transition: all .5s ease;

        &:hover {
          background-color: var(--color-near-white);
        }
      }

      .delete {
        width: 150px;
        border: solid 1px var(--color-red-500);
        background-color: var(--color-red-500);
        color: white;
        padding: 7px 30px;
        border-radius: 20px;
        transition: all .5s ease;

        &:hover {
          background-color: #f43e06;
        }
      }
    }
  }
}