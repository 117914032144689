.modal-confirmation {
  .modal-confirmation_title {
    color: var(--color-blue-700);
  }

  .modal-confirmation_icon {
    font-size: 60px;
    color: var(--color-blue-700);
  }

  .modal-confirmation_close {
    border: none;
    background: white;
    margin-top: 10px;
  }

  .spanModal {
    color: var(--color-blue-700);
  }
}