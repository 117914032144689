.map-gl {
  width: 100%;
  height: 100%;

  .map-zoom {
    position: relative;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.698);
    padding: 5px 10px;
    border-radius: 4px;
    bottom: calc(-100% - 10px);
    left: 10px;
    width: 100px;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;
    opacity: 0;
    transition: all 0.15s ease-in-out;
    color: rgb(16, 16, 16);
  
    &.displayed {
      opacity: 1;
    }
  }
  
  .navigation {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.829);
    width: 40px;
    border-radius: 10px;
    padding: 2px;
    right: 10px;

    button {
      border: none;
      background-color: transparent;
      color: black;

      :disabled {
        cursor: not-allowed;
      }
    }

    .zoom {
      display: flex;
      flex-direction: column;
    }
  }
}