.configList_cont--item {
  background: var(--color-white);
  border-radius: 7px;
  font-size: 14px;
  padding: 5px;
  color: var(--color-grey-800);
  margin-bottom: 8px;
  justify-content: space-between;

  .spanList {
    margin-left: 10px;
    display: flex;
    align-self: center;
  }

  .firstDiv {
    display: flex;
  }

  .buttonDeleteComplexeSa {
    border: none;
    background: rgba(255, 255, 255, 0);
  }

  
}

.popup-span {
    background-color: black;
    color: white;
    font-size: 0.7rem !important;
}