.main {
  background-color: white;
  height: 100%;
  padding: 5px;
  font-size: 14px !important;
  padding: 10px;

  .input-admin {
    color: var(--color-near-black);
    font-size: 12px;
    font-weight: 500;
    background-color: #f2f2f2;
    background-image: none !important;
  }

  .input-admin:focus {
    color: var(--color-near-black);
  }

  .switch-active {
    font-weight: 700;
    color: var(--color-blue-700)
  }

  .switch {
    &.switch-jalon {
      font-style: italic,
    }
  }
   

  .delete-button {
    color: var(--color-red-600)
  }

  .see-button {
    color: var(--color-blue-700)
  }

  .btn-gradient-blue {
    background-color: var(--color-blue-500);
    background-image: linear-gradient(to right,rgba(255,255,255,0),var(--color-blue-700) 100%);
    color: #fff;
    background-clip: padding-box;
    transition: all .5s ease;
    font-size: 14px;
    font-weight: 500;
    border-radius: 18px;
  }

  .btn-gradient-blue:disabled {
    color: #a0a0a0;
    background-color: #d7d7d7;
    border: none;
    cursor: not-allowed;
    background-image: none;
  }

  .btn-outline-grey {
    border: 1px solid var(--color-grey-500);
    padding: 5px 10px;
    font-size: 12px;
    color: #747678;
    border-radius: 15px;

    &:hover {
      background-color: #3a3b3c;
      color: white
    }
  }

  .btn-active {
    background-color: #747678;
    color: white
  }

  .pointer {
    cursor: pointer;
  }

  .national {
    font-size: 14;
    font-weight: 400;
  }

  .red-flag {
    color: var(--color-red-600);
  }
  .autocomplete-admin {
    background-color: #fff;
    padding: 0;
    height: 100%;
    .MuiOutlinedInput-root {
      padding: 0 !important;
      .MuiAutocomplete-input {
        font-size: 12px;
        font-weight: 500;
        padding-left: 1.25rem !important;
        width: 100%;
      }
    }
    .MuiChip-root {
      border-radius: 4px !important;
    }
  }
}

.modal-admin {
  .modal-title {
    color: var(--color-red-600);
  }
  .modal-warning {
    color: var(--color-red-600);
  }
}

.search-icon {
  margin-right: 8px;
  color: #949598;
  cursor: text;
}

.dessociate-btn {
  &:hover {
    background-color: white !important;
  }

  &:focus {
    outline: none;
  }
}

.form-container-entity {
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 100% !important;
  }
}


.construction-site-admin {

  .input-ligne {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .search-gaia-ligne {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .active {
      background-color: var(--color-blue-500);
      color: white;
    }
  
    .left {
      border-radius: 10px 0px 0px 10px;
    }
    .right {
      border-radius: 0px 10px 10px 0px;
    }
    .switch-button {
      border: none;
      padding: 5px 10px;
      min-width: 80px;
      font-size: 12px;

    }
  }

  
}