.composite {
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: white;
  border-radius: 3px;
  padding: 4px 5px 3px 5px;
  font-size: 12px;
  font-weight: 800;
  background-color: #C6C6C6;
}