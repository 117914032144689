.contributions-table {
    width: 100%;
    max-width: 100%;
    padding: 5px;
    padding-top: 0px;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
  
    .arrayComponent-header {
      z-index: 3;
      color: #72767A;
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0 10px;
  
      .arrayComponent-header-first {
        display: flex;
        flex-direction: row;
        background: white;
        width: 15%;
        max-width: 20%;
        .arrayComponent-header-col1 {
          display: flex;
          height: 100%;
          width: 50%;
        }
        .arrayComponent-header-col2 {
          display: flex;
          height: 100%;
          width: 50%;
        }
      }
      
      .arrayComponent-header-second {
        display: flex;
        flex-direction: row;
        flex: 1;
        background: white;
  
        .arrayComponent-header-col4 {
          width: 20%;
        }
        .arrayComponent-header-col5 {
          width: 40%;
        }
        .arrayComponent-header-col6 {
          width: 40%;
        }
      }

      .arrayComponent-header-third {
        display: flex;
        flex-direction: row;
        background: white;
        width: 15%;
        max-width: 20%;
        .arrayComponent-header-col7 {
          display: flex;
          height: 100%;
          width: 100%;
        }
      }
    }
  
    .arrayComponent-body {
      padding-top: 20px;
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
  
      .arrayComponent-body-container {
        display: flex;
        flex-direction: column;
  
        .arrayComponent-separate {
          border-bottom: solid 1px rgba(128, 128, 128, 0.45);
        }
      }
      .arrayComponent-body-section {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 5px;
  
  
        .arrayComponent-body-section-first {
          align-self: flex-start;
          display: flex;
          flex-direction: row;
          width: 15%;
          position: sticky;
          top: 40px;
  
          .arrayComponent-body-col1 {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 50%;

            .libelle {
              display: flex;
              font-size: 28px;
              font-weight: 800;
              span {
                padding: 3px 5px 1px 5px;
                background-color: #F1F6F9;
              }
            }

            .eic {
              font-size: 12px;
              span {
                padding: 2px;
                background-color: #F1F6F9;
              }
            }

            .component {
              display: flex;
              align-items: center;
              gap: 5px;

              .type {
                font-weight: 800;
                font-size: 22px;
              }

              .abrev {
                font-size: 14px;
              }
            }
  
          }

          .arrayComponent-body-col2 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 50%;

            .cotation-global {
              display: flex;
              gap: 3px;
              align-items: center;
              font-size: 18px;
              padding: 12px 10px;
              font-weight: 800;
              border-radius: 10px;
            }
          }
        }
  
        .arrayComponent-body-section-second-container {
            display: flex;
            flex-direction: column;
            width: 70%;
            flex: 1;
  
          .arrayComponent-body-section-second-noProposal {
            margin-bottom: 20px;
            width: 100%;
            text-align: center;
          }
          .arrayComponent-body-section-second {
            padding: 10px 0;
            display: flex;
            flex-direction: row;
            width: 100%;
            border-radius: 5px;
  
            &:nth-child(odd) {
              background: #F2F2F2;
            }
  
            .arrayComponent-body-col4 {
              max-width: 20%;
              width: 20%;
              padding-right: 10px;
              display: flex;
              flex-direction: column;
              padding: 0 10px;
  
              .arrayComponent-body-col4-first {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: flex-start;
                .arrayComponent-body-col4-cotation {
                  text-align: center;
                  border-radius: 5px;
                  height: 30px;
                  width: 30px;
                  color: white;
                  font-weight: bold;
                  background: #1B9EE8;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
  
                .arrayComponent-body-col4-date {
                  margin-left: 10px;
                  font-weight: 500;
                  font-size: 12px;
                }
              }
      
              .arrayComponent-body-col4-username {
                display: flex;
                flex-direction: column;
        
                .arrayComponent-body-col4-name {
                  font-weight: bold;
                  display: flex;
                  gap: 10px;
  
                    .axe-icon {
                      height: 20px;
                      width: 20px;
                    }
                }
                .arrayComponent-body-col4-function {
                  font-style: italic;
                  font-size: 14px;
                }
              }
            }
            .arrayComponent-body-col5 {
              max-width: 40%;
              width: 40%;
              padding-right: 10px;
              font-size: 14px;
              white-space: pre-line;
  
              .arrayComponent-body-col5-file {
  
                margin-top: 10px;
  
                .paperclip {
                  margin-top: 5px;
                }
                .see-file {
                  font-size: 12px;
                  margin-left: 5px;
                  border: none;
                  padding: 5px;
                  border-radius: 8px;
                  background: #F2F2F2;
                  
                  &:hover {
                    background: #838383;
                    color: #fff
                  }
                }
              }
            }
            .arrayComponent-body-col6 {
              max-width: 40%;
              width: 40%;
              font-size: 14px;
              white-space: pre-line;
            }
          }
        }

        .arrayComponent-body-section-third-container {
          display: flex;
          flex-direction: column;
          width: 15%;
          padding-left: 10px;
          gap: 20px;

          .action-container {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 10px;
            min-height: 80px;
            border-radius: 5px;

            &:nth-child(even) {
              background: #F2F2F2;
            }

            .action-button {
              display: flex;
              border: none;
              text-align: left;
              background: transparent;
              font-weight: 700;
              font-size: 15px;
              text-decoration: underline;
              color: #4d4f53;
            }

            .icons {
              margin-left: 10px;
            }
          }
        }
      }
    }
}