.modalAddAction-container {
  overflow-y: auto;
}

.modalAddAction {
  position: absolute;
  width: 1200px;
  min-width: 1100px;
  margin: 20px calc((100vw - 1200px)/2);
  height: 95vh;
  min-height: 95vh;
  background-color: #fff;
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .modalAddAction-contribution {
    background: var(--color-lighter-grey);
    width: 50%;
    padding: 10px 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;

    .modalAddAction-contribution-numComposant {
      color: #4d4f53;
      font-size: 60px;
      font-weight: 900;
    }

    .modalAddAction-contribution-jalon {
      color: #4d4f53;
      font-size: 40px;
      font-weight: 900;
    }

    .modalAddAction-contribution-libelle {
      font-size: 20px;
    }

    .modalAddAction-contribution-subtitle {
      text-align: center;
      line-height: 2px;
      border-bottom: 1px solid;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 700;
      box-sizing: border-box;

      .modalAddAction-contribution-subtitle-item {
        background: var(--color-lighter-grey);
        padding: 0 20px;
      }
    }

    .modalAddAction-contribution-container {
      display: flex;
      flex-direction: column;
      height: 80vh;
      
      overflow-y: auto;
    }
  }
  .modalAddAction-action {
    padding: 20px 20px;
    width: 50%;

    .modalAddAction-action-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .modalAddAction-action-title-text {
        font-size: 28px;
        color: #4d4f53;
      }
    }

    .modalAddAction-action-add-container {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .modalAddAction-action-add {
        display: flex;
        flex-direction: row;
        align-items: center;
  
        span {
          padding-top: 2px;
          margin-left: 10px;
        }
      }
    }

    .modalAddAction-action-body {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .modalAddAction-action-body-empty {
        font-style: italic;
        margin-top: 20px;
      }
    }
  }
}