.wrapper-select {
  .MuiOutlinedInput-root {
    border-radius: 20px !important;
    color: #747678 !important;
    box-shadow: 0 0 3px 2px #e1e1e1;
    width: 15rem;
  }
  .Mui-focused {
      border-color: #bdbdbd !important;
      border: none !important;
      outline: none !important;
  }
  .MuiButton-root {
      margin-left: 0.5rem;
      text-transform: none !important;
      background-color: #747678!important;
      color: #fff !important;
      border-radius: 20px !important;
  }
  .MuiButton-root:focus {
      outline: none !important;
  }
}