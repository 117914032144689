.formSACreate {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .formSACreate-item {
    min-width: 49%;
  }
}

.select-category {
  position: relative;
  display: flex;
  text-align: center;
  line-height: 2px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  background: linear-gradient(180deg, 
    rgba(0,0,0,0) calc(50% - 1px), 
    rgba(192,192,192,1) calc(50%), 
    rgba(0,0,0,0) calc(50% + 1px)
  );

  .title {
    background-color: white;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
  }
}