.modalCopileMode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  min-width: 600px;
  min-height: 80%;
  background-color: var(--color-lighter-grey);
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  max-height: 95vh;
  overflow: auto;
  

  .modalCopileMode-container {
    display: flex;
    flex-direction: column;
    

    .modalCopileMode-name {
      font-size: 20px;
      font-weight: 900;

      display: flex;
      gap : 30px;

      .axe-icon {
        margin-right: 20px;
        margin-top: 5px;
        height: 25px;
        width: 25px;
        padding-bottom: 5px;
  
        path {
          fill: #4d4f53;
        }
      }
    }

    .modalCopileMode-description {
      margin-top: 15px;
      font-size: 14px;
    }

    .modalCopileMode-chip {
      display: flex;
      flex-direction: row;
      margin-top: 15px;

      .modalCopileMode-chip-status {
        margin-left: 10px;
        display: flex;
        align-items: center;
        gap: 10px;

        div {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-bottom: 2px;
        }

        span {
          justify-content: center;
          vertical-align: middle;
          font-weight: 800;
          color: #4d4f53;
          font-size: 14px;
        }
      }

      .modalCopileMode-chip-state {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 20px;

        div {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-bottom: 2px;
        }

        span {
          justify-content: center;
          vertical-align: middle;
          font-weight: 800;
          color: #4d4f53;
          font-size: 14px;
        }
      }
    }

    .modalCopileMode-dateAndUser {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .modalCopileMode-dateAndUser-user {
        display: flex;
        flex-direction: column;

        .modalCopileMode-dateAndUser-user-pilote {
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            margin-right: 10px;
            padding: 10px;
            border-radius: 50%;
            min-width: 36px;
            text-align: center;
            color: white;
            font-size: 12px;
          }

          .modalCopileMode-dateAndUser-user-pilote-name {

            .modalCopileMode-dateAndUser-user-pilote-name-organisation {
              font-style: italic;
            }
          }
        }

        .modalCopileMode-dateAndUser-user-copilote {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10px;

          span {
            margin-right: 10px;
            padding: 10px;
            min-width: 36px;
            text-align: center;
            border-radius: 50%;
            color: white;
            font-size: 12px;
          }

          .modalCopileMode-dateAndUser-user-copilote-name {
            .modalCopileMode-dateAndUser-user-copilote-name-organisation {
              font-style: italic;
            }
          }
        }
      }

      .modalCopileMode-dateAndUser-date {
        display: flex;
        flex-direction: column;
  
        .modalCopileMode-dateAndUser-date-close {
          .modalCopileMode-dateAndUser-date-close-operator {
            font-weight: 900;
            margin-right: 5px;
          }
          .modalCopileMode-dateAndUser-date-close-title {
            font-weight: 900;
            color: #4d4f53;
            margin-right: 15px;
          }
          .modalCopileMode-dateAndUser-date-close-item {
            padding: 5px;
            background: white;
            border-radius: 5px;
          }
        }

        .modalCopileMode-dateAndUser-date-open {
          margin-bottom: 15px;
          .modalCopileMode-dateAndUser-date-open-title {
            font-weight: 900;
            color: #4d4f53;
            margin-right: 5px;
          }
  
          .modalCopileMode-dateAndUser-date-open-item {
            padding: 5px;
            background: white;
            border-radius: 5px;
          }
        }
      }
    }

    .modalCopileMode-dateAndUser-entities {
      display: flex;
      
      strong {
        padding-top: 10px;
      }
    }

    .modalCopileMode-avancement-A {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      .modalCopileMode-avancement-A-title {
        position: relative;
        display: flex;
        text-align: center;
        line-height: 2px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 700;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;

        background: linear-gradient(180deg, 
          rgba(0,0,0,0) calc(50% - 1px), 
          rgba(192,192,192,1) calc(50%), 
          rgba(0,0,0,0) calc(50% + 1px)
        );

        .modalCopileMode-edit-mode {
          position: absolute;
          right: 0;
          .modalCopileMode-edit-mode-close {
            font-size: 20px;
            border: none;
            color: rgb(205, 0, 55);
            cursor: pointer;
          }

          .modalCopileMode-edit-mode-check {
            font-size: 20px;
            border: none;
            color: green;
            cursor: pointer;
          }
        }

        .modalCopileMode-avancement-A-title-text {
          background-color: var(--color-lighter-grey);
          text-transform: uppercase;
        }

        

        .modalCopileMode-avancement-weather-select {
          border: solid 1px var(--color-light-grey);
          display: flex;
          position: absolute;
          left: 0;

          .modalCopileMode-avancement-weather-button {
            border: none;
            cursor: pointer;

            :focus {
              border: none;
              outline: 0px auto -webkit-focus-ring-color;
            }
            
            .weather-icon {
              height: 20px;
              width: 20px;

              svg {
                height: 0.7em;
              }
            }
          }
        }
        
        
        .modalCopileMode-edit-icon {
          position: absolute;
          right: 0;
          cursor: pointer;
          background-color: var(--color-lighter-grey);
          border: none;
        }
      }

      .modalCopileMode-avancement-A-description {
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .weather-icon {
          min-width: 30px;
        }

        .icon-anim {
          margin-left: auto;
        }

        .modalCopileMode-avancement-A-description-text, .modalCopileMode-avancement-A-description-textarea {
          flex: 1;
          margin-right: 38px;
          white-space: pre-line;
        }
      }
    }

    .noComment {
      font-style: italic;
    }

    .modalCopileMode-action-button {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .modalCopileMode-visibility {

      margin-top: 10px;
      >div {
        flex: 1;
      }
      .modalCopileMode-visibility-button {
        font-size: 20px;
        border: none;
        color: green;
        cursor: pointer;

        &:disabled {
          color: grey;
          cursor: default;
        }
      }
    }
  }
}


.modalCopileMode-dateAndUser-entities {
  display: flex;
  .modalCopileMode-entity-list {
    display: flex;
    flex-direction: column;
    max-height: 100px;
    overflow-y: auto;
  }
  .modalCopileMode-dateAndUser-entities-item {
    min-width: 200px;
    margin-top: 5px;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    margin-left: 0.5rem;
  }
}