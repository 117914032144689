.jalons {
    width: 470px;
}

.multiselect-jalons {
    display: flex;
    align-self: start;
    flex-direction: column;
    width: 100%;
}

.tab-button {
    outline: none !important;
}

.autocomplete-jalon {
    background-color: #fff !important;
    padding: 0 !important;
    height: 100% !important;
    .MuiOutlinedInput-root {
      padding: 0 !important;
      .MuiAutocomplete-input {
        font-size: 12px;
        font-weight: 500;
        padding-left: 1.25rem !important;
        width: 100% !important;
      }
    }
    .MuiChip-root {
      border-radius: 4px !important;
    }
}

p.object-jalon-composant-label {
  margin: 0;
  font-size: 1rem;
}