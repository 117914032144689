.action-attribute {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .action-attribute-chip-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .action-attribute-weather-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .action-attribute-chip {
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 15px;
    background: white;
    color: black;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.late {
      background: #FB8B72;
      color: white;
    }
  }
}

.actionTile {
  background-color: var(--color-lighter-grey);
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  border: none;

  .delimiter {
    border-bottom: 1px solid #4d4f5348;
  }

  .actionTile-header {
    width: 100%;
    position: relative;

    .axe-icon {
      margin-right: 20px;
      margin-top: 5px;
      height: 25px;
      width: 25px;
      padding-bottom: 5px;

      path {
        fill: #4d4f53;
      }
    }
    .actionTile-title {
      color: #242830;
      font-weight: 900;
      font-size: 18px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: start;
      flex: 1;
    }

    .actionTile-clone {
      all: unset;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #fff;
      padding: 4px;
      border-radius: 5px;

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }

      svg {
        margin-right: 10px;
      }
    }

    .actionTile-clone-dropdown {
      position: absolute;
      display: flex;
      flex-direction: column;
      right: 0px;
      top: 30px;

      button {
        all: unset;
        background-color: #fff;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  .actionTile-description {
    color: #242830;
    font-size: 15px;
    margin: 0;
    > p {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 0;
      font-size: 14px;
      color: #242830;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      text-align: justify;
      white-space: pre-line;
    }
  }

  .actionTile-atribute {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;

    .actionTile-atribute-status {

      .actionTile-atribute-status-isValid {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 20px;

        div {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-bottom: 2px;
        }

        span {
          justify-content: center;
          vertical-align: middle;
          font-weight: 800;
          color: #4d4f53;
          font-size: 14px;
        }
      }

      .actionTile-atribute-status-isDo {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-bottom: 2px;
        }

        span {
          justify-content: center;
          vertical-align: middle;
          font-weight: 800;
          color: #4d4f53;
          font-size: 14px;
        }
      }
    }

    .actionTile-atribute-status {
      display: flex;
      flex-direction: row;
    }

    .actionTile-atribute-meteo {
      display: flex;
      flex-direction: row;

      .actionTile-atribute-meteo-pa {
        display: flex;
        flex-direction: row;
        margin-right: 30px;
        align-items: center;

        span {
          margin-right: 10px;
        }
      }

      .actionTile-atribute-meteo-a {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          margin-right: 10px;
        }
      }
    }
    border-bottom: 1px solid #4d4f5348;
    padding-bottom: 15px;
  }

  .actionTile-identity {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .actionTile-identity-name-container {
      display: flex;
      flex-direction: column;
    }

    .actionTile-identity-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .actionTile-identity-name-no-pilot {
        font-weight: 600;
      }

      span {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        color: white;
        font-size: 12px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          padding-top: 1px;
        }
      }

    }

    .actionTile-identity-name-copilote {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      gap: 10px;

      .actionTile-identity-name-no-pilot {
        font-weight: 600;
      }

      span {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        color: white;
        font-size: 12px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          padding-top: 1px;
        }
      }

    }

    .actionTile-identity-date {
      display: flex;
      flex-direction: column;

      .actionTile-identity-date-close {

        .actionTile-identity-date-close-operator {
          font-weight: 900;
          margin-right: 5px;
        }
        .actionTile-identity-date-close-title {
          font-weight: 900;
          color: #4d4f53;
          margin-right: 15px;
        }
        .actionTile-identity-date-close-item {
          padding: 5px;
          background: white;
          border-radius: 5px;
        }
      }

      .actionTile-identity-date-open {
        margin-bottom: 15px;
        .actionTile-identity-date-open-title {
          font-weight: 900;
          color: #4d4f53;
          margin-right: 5px;
        }

        .actionTile-identity-date-open-item {
          padding: 5px;
          background: white;
          border-radius: 5px;
        }
      }
    }
  }
}

.actionTile-identity-entities {
  margin-top: 10px;
  display: flex;
  
  strong {
    padding-top: 10px;
  }

  .actionTile-identity-entities-container {
    display: flex;
    flex-direction: column;
    max-height: 100px;
    max-width: 90%;
    overflow: auto;
  }

  .actionTile-identity-entities-item {
    margin-top: 5px;
    margin-left: 10px;
    padding: 5px;
    border-radius: 5px;
    background: white;
  }
}