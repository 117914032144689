.modal-user-infos-modal{
  overflow: auto;

  .modal-user-infos {
    position: absolute;
    width: 750px;
    height: 20vh;
    margin: 35vh calc((100vw - 750px)/2);
    min-width: 500px;
    min-height: 200px;
    background-color: #fff;
    box-shadow: 24px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-user-infos-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;

      .modal-user-infos-title {
        margin: auto 0;
      }
    }

    .modal-user-infos-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .modal-user-infos-content-row {
        width: 100%;
        display: flex;

        strong {
          width: 100px;
        }

        span {
          max-width: 600px;
        }

        a.modal-user-infos-content-row-mail {
          margin-left: 1ch;
        }
      }
    }
  }

}
