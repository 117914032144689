.data-information {
  margin-top: 10px;
  label {
    span {
      font-size: 14px;
    }
  }

  .MuiCheckbox-root {
    padding: 0px 9px 9px 9px;
  }

  .button {
    margin: 5px 0px 10px 0px;
    display: flex;
    justify-content: center;
    button {
      font-size: 14px;
      border: none;
      background: transparent;
      color: #143d5f;
      cursor: pointer;
    }
    
  }

  .information-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--color-medium-grey);

    .item {
      display: flex;
      flex-direction: column;
    }
  }
}