.configList_cont {
  height: 200px;
  color: #333;
  overflow: auto;
  background: var(--color-grey-200);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  flex: 0 1 50%;
}

.configList {
  background: var(--color-grey-200);
  height: 300px;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;

  .configList_cont{
    padding: 0;
    
    .configList_cont--item {
      background: var(--color-white);
      border-radius: 7px;
      font-size: 14px;
      padding: 5px;
      color: var(--color-grey-800);

      .spanList {
        margin-left: 10px;
      }
    }

    .configList_cont--item:hover {
      background: #e05206;
      color: var(--color-white);
    }
  }
}