.dzp-cotation-table {
  height: cal(100vh - var(--header-height) - 54px);

  .MuiTableCell-root {
    max-width: 145px;
  }

  .footer {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 1;

    .sticky-footer-label {
      position: sticky;
      left: -11px;
      bottom: 0;
      background-color: white;
      z-index: 3;
    }
  }

  .component {
    font-weight: 900;
  }

  .cotation {
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    width: 135px;
    height: 30px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    display: flex;
    flex-direction: column;
    width: 135px;

    div {
      display: flex;
      align-items: center;
      gap: 5px;

      .type {
        font-weight: 900;
        font-size: 20px;
      }

      .abrev {
        font-weight: 500;
        padding-top: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 90px;
      }

      .status {
        width: 10px;
        height: 10px;
        border: 1px solid black;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }

  .bold {
    font-weight: 800;
    font-size: 14px;
    flex-wrap: wrap;
  }

  .table-container {
    height: calc(100vh - 170px);
    width: calc(100vw - 100px);
    overflow: auto;
    padding: 0 10px;
    box-shadow: 0 0 5px 1px #e1e1e1;

    .MuiTableCell-root {
      padding: 12px;
    }
  }

  .separation {
    border-top: 1px solid black;
  }

  .separate {
    border-bottom: 1px solid black;
  }

  .indice {
    display: flex;
    width: 140px;
    align-items: center;
    justify-content: center;
    padding-right: 5px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      width: 80%;
      background-color: #ECECEC;
      padding: 3px 5px;
      border-radius: 5px;
      color: var(--color-carbone);
      font-weight: 800;
    }
  }

  .gravity {
    display: flex;
    width: 140px;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      background-color: #ECECEC;
      padding: 3px 5px;
      border-radius: 5px;
      color: var(--color-carbone);
      font-weight: 800;
    }
  }

  .date {
    background-color: #ECECEC;
    padding: 0px 5px;
    border-radius: 5px;
    font-size: 10px;
  }

  .eic {
    text-align: center;
    background-color: #ECECEC;
    padding: 0px 5px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 65px;

  }

  .sticky-header {
    position: sticky;
    left: -11px;
    bottom: 0;
    background-color: white;
    z-index: 3;
  }

  .sticky-component {
    position: sticky;
    left: -11px;
    background-color: white;
    z-index: 1;
  }
}