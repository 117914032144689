.settingExportPdf-modal {
  overflow: auto;
}

.settingExportPdf {
  position: absolute;
  width: 900px;
  min-width: 900px;
  margin: 20px calc((100vw - 900px)/2);
  height: 850px;
  min-height: 850px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }

  .settingExportPdf-buttons {
    display: flex;
    flex-direction: row;
    align-self: end;
    margin-top: 10px;
    margin-right: 30px;

    .settingExportPdf-buttons-item {
      margin-right: 30px;
    }
  }

  .settingExportPdf-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: var(--color-blue-700);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    .settingExportPdf-title-icon {
      font-size: 80px;
    }

    .settingExportPdf-title-text {
      font-weight: bold;
    }
  }

  .settingExportPdf-condition {
    margin-top: 10px;
    .settingExportPdf-condition-text {
      color: #cd0037;
      font-weight: bold;
    }

    .settingExportPdf-condition-checkbox {
      align-items: center;
    }
  }


  .settingExportPdf-body {
    width: 100%;
    min-width: 100%;
    padding: 10px;

    &.disabled {
      background-color: grey;
      border-radius: 20px;
    }

    .settingExportPdf-body-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }

      .settingExportPdf-body-section-block {
        width: 50%;
        min-width: 50%;

        .settingExportPdf-body-section-block-title {
          font-size: 15px;
          color: var(--color-carbone);
        }

        .settingExportPdf-body-section-block-switch {
          align-items: center;
          margin-left: 100px;
        }

      }
    }
  }

  .settingPdf-display {
    border-radius: 20px;
    width: 100%;
  }

  .settingPdf-display-btn-left {
    border-radius: 40px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .settingPdf-display-btn-right {
    border-radius: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .btn-outline-grey {
    border: 1px solid var(--color-grey-500);
    padding: 5px 10px;
    font-size: 12px;
    color: #747678;

    &:hover {
      background-color: #3a3b3c;
      color: white
    }
  }

  .btn-active {
    background-color: #747678;
    color: white
  }

  .btn-disabled {
    opacity: 0.6;
    background-color: transparent !important;
    color: #747678 !important;

    &.btn-active {
      background-color: #747678 !important;
      color: white !important;
    }

    &:hover {
      cursor: default;
    }
  } 

  .settingPdf-perimeter-column-label {
    color: var(--color-carbone);
    font-size: 15px;
    font-weight: bold;
  }

  .settingPdf-perimeter-column {
    margin-left: 20px;
    height: 100px;
    overflow-y: auto;

    .settingPdf-permiter-column-empty {
      font-size: 12px;
    }

    span {
      font-size: 12px;
    }

    &.entity {
      height: 250px;
    }

    .settingPdf-perimeter-column-label-checkboxAll {
      margin-bottom: -0.5rem;
    }

    .settingPdf-perimeter-column-label-checkbox {
      margin-bottom: -1rem;
      font-size: 0.5em !important;
    }
  }

  .settingExportPdf-body-ct {
    margin-top: 10px;
  }

  .settingExportPdf-body-complexe {
    padding-right: 20px;
  }

}