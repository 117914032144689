.tab-element {
  border: none;
  background-color: transparent;
  &:hover, &.selected, &:focus {
    background-color: var(--color-grey-800);
    outline: none;
    cursor: pointer;
  }

  &:hover {
    .title {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .title {
    color: var(--color-lighter-grey);
    font-size: 12px;
    font-weight: 900;
    font-family: Avenir;
  }
}

 li {
    display: flex;
    width: auto;
    height: 100%;
  }

  .iconNav {
    color: white;
  }

  .sub-menu {
    z-index: 5;
    .background {
      border-radius: 0;
      background-color: white;
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: #01080E;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      width: 100%;
      box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.04), 2px 3px 11px 0px rgba(0, 0, 0, 0.06);

      .menu-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      .link {
        color: #01080E;
        line-height: 20px;
        padding: 5px 10px;


        &:hover {
          color: #01080E;
          background-color: var(--color-lighter-grey);
        }
      }
    
    }
  }