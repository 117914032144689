.modal-global-contribution {

  display: flex;
  flex-direction: column;
  gap: 15px;

  .libelle-contribution {
    color: #242830;
    font-weight: 800;
  }
  .cotations {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    min-height: 34px;
  }

  .cotation-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 140px;

    .selected {
      scale: 1.2;
    }

    .gravity {
      background-color: black;
    }

    button {
      border: none;
      padding-top: 2px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      font-weight: 800;
      border-radius: 3px;
      min-width: 27px;
      background-color: #C6C6C6;

      &:focus {
        outline: none;
      }

      span {
        text-align: center;
      };
    }
  }

  .separator {
    text-align: center;
    line-height: 1px;
    border-bottom: 1px solid;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    box-sizing: border-box;
    color: #ADADAD;

    span {
      background: white;
      padding: 0 20px;
    }
  }

  .comment {
    width: 100%;
  }

  .contribution-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    div {
      text-align: center;
    }

    .button-cancel  {
      border: none;
      background: transparent;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 800;
      line-height: 14px;

      &:focus {
        outline: none;
      }
    }

  }
}