
.modal-object-composants {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: #FFF;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 32px;
  height: 550px;
  .MuiAutocomplete-root {
      height: 100%;
  }

  .modal-object-composants-header {
    .MuiTypography-root {
      font-weight: 500 !important;
      font-size: 1.25rem !important;
    }
    .MuiIconButton-root {
        outline: none;
    }
  }

  .modal-object-composants-body {
    > p {
      margin-left: 0.5rem;
      margin-top: 1rem;
      font-weight: 400;
      font-size: 1rem;
    }

    .MuiAutocomplete-popper ul {
      max-height: 25vh;
    }
  }

  .modal-object-composants-button {
    margin-top: auto;
  }
}
