

ul.contributors-list {
  min-height: 300px;
  max-height: 70vh;
  color: #333;
  overflow: auto;
  background: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  flex: 0 1 100%;

  li.contributors-item {
    width: auto;
    height: auto;
    padding: 1rem;
    margin-bottom: 0.5rem;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: var(--color-grey-200);
    }

    .item-container {
      display: flex;
      position: relative;

      .buttonDeleteContributorSa {
        border: none;
        background: rgba(255, 255, 255, 0);
        position: absolute;
        padding: 0;
        right: 0;
      }

      .button-infos {
        display: flex;
        border: none;
        background: transparent;
        text-align: left;

        &:hover {
          background: #f2f2f2;
          border-radius: 5px;
        }

        &:focus {
          outline: none;
        }
      }
  
      .item-name {
        padding-right: 1rem;
        border-right: 1px solid #d7d7d7;
        flex: 1 1 0px;
      }
  
      .item-function {
        padding: 0 1rem;
        border-right: 1px solid #d7d7d7;
        flex: 1 1 0px;
      }
  
      .item-data {
        padding-left: 1rem;
        flex: 2 1 0px;
  
        .item-complexes, .item-lignes {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.no-contributor {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem;
  font-style: italic;
}