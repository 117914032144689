
#contributions {
  height: calc(100vh - var(--topbar-height));
  background-color: var(--color-white);

  .contributions-header {
    padding: 0.5rem 1.5rem;
    align-items: center;
    border-bottom: 1px solid var(--color-light-grey);
    height: 80px;

    .switch-all {
      margin-left: auto;
    }

    .back-button {
      color: var(--color-carbone);
    }

    .header-subtitle {
      color: var(--color-blue-600);
    }
  }

  .contributions-main-container {
    flex: 1;
    height: calc(100% - 80px);
    .panel {
      flex: 1 1 50%;
    }

    .left-panel {
      border-right: 1px solid var(--color-lighter-grey);

      .component-navigator {
        padding: 1rem;
        .component-name {
          flex: 1;
          justify-content: space-between;
          margin: 0 1rem;
          padding: 1rem;
          border-radius: 40px;
          box-shadow: 0 0 10px 2px var(--color-light-grey);

          button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            padding: 0;
            color: #007bff;
          }
        }

        .disabled {
          color: var(--color-light-grey);
        }

        .button {
          cursor: pointer;
        }
      }

      .contributions-list {
        flex: 1;
        overflow-y: auto;

        .no-contributions {
          padding: 1rem;
          font-style: italic;
        }

        .history-button {
          color: var(--color-blue-600);
          cursor: pointer;
        }
      }

      .contributions-history {
        opacity: 0.7;
      }
    }
    .right-panel {
      background-color: var(--color-lighter-grey);
      border-left: 1px solid var(--color-light-grey);
      overflow-y: auto;

      .contribution-editor {
        span {
          font-weight: 500;
        }

        .duping-textfield {
          cursor: pointer;
          & * {
            cursor: pointer;
          }
        }

        .contribution-field {
          background-color: #e1e1e1;
          border-radius: 4px;

          .MuiInputBase-root {
            margin: 0.5rem 1rem;
          }
        }

        .file-upload {
          label {
            margin-bottom: 0;
            cursor: pointer;
            white-space: nowrap;
            max-width: 250px;
            text-overflow: ellipsis;
            overflow: hidden;

            &.disabled {
              cursor: default;
            }
          }

          svg {
            height: 100%;
          }

          .delete-button {
            color: var(--color-red-600);
          }
        }
      }
    }

  }

  .btn-outline-grey {
    border: 1px solid var(--color-grey-500);
    padding: 5px 10px;
    font-size: 12px;
    color: #747678;

    &:hover {
      background-color: #3a3b3c;
      color: white
    }
  }

  .btn-active {
    background-color: #747678;
    color: white
  }

  .btn-disabled {
    opacity: 0.6;
    background-color: transparent !important;
    color: #747678 !important;

    &.btn-active {
      background-color: #747678 !important;
      color: white !important;
    }

    &:hover {
      cursor: default;
    }
  }  
}