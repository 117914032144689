.modal-error {
  .modal-error_title {
    color: var(--color-red-600);
  }

  .modal-error_warning {
    color: var(--color-red-600);
  }

  .modal-error_content {
    text-align: center;
    font-size: 18px;
    color: var(--color-grey-800);
  }

  .modal-error_button {
    color: var(--color-grey-800);

  }
}
