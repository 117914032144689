.modalAddActionTile {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;


  .modalAddActionTile-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .axe-icon {
      margin-right: 20px;
      margin-top: 5px;
      height: 25px;
      width: 25px;
      padding-bottom: 5px;

      path {
        fill: #4d4f53;
      }
    }

    .modalAddActionTile-title-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .modalAddActionTile-title-icon {
        height: 30px;
        width: 30px;
        min-width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        text-transform: uppercase;
        color: #fff;
        font-size: 12px;
        span {
          padding-top: 1px;
        }
      }
    }

    .modalAddActionTile-title-date {
      font-weight: 900;
      color: #4d4f53;
    }
  }

  .modalAddActionTile-body-analyse {
    font-weight: 900;
    
  }
  .modalAddActionTile-body-analyse-text {
    color: #4d4f53;
    font-size: 12px;
    margin-top: 5px;
    border-bottom: 2px solid #4d4f5348;
    padding-bottom: 15px;
    white-space: pre-line;
  }

  .modalAddActionTile-body-proposition {
    font-weight: 900;
  }

  .modalAddActionTile-body-proposition-text {
    color: #4d4f53;
    font-size: 12px;
    margin-top: 5px;
    white-space: pre-line;
  }

  .modalAddActionTile-button {
    text-align: end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      border: 1px solid #4d4f53;
      background: white;
      border-radius: 20px;
      padding: 10px 10px;
      color: black;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 5px;
        font-size: 15px;
      }
    }
  }
}