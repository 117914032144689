.contribution-tile {
  padding: 1rem;
  cursor: pointer;

  &.selected {
    background-color: var(--color-grey-300);
  }

  >.contribution-tile-header {

    .rating {
      width: 45px;
      text-align: center;
      padding: 11px 13px;
      border-radius: 10px;
      color: var(--color-white);
    }
  }

  >.contribution-tile-data {
    border-radius: 0.5rem;
  }

  .contribution-tile-action {
    background-color: var(--color-white);
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
  }

  .author-avatar {
    font-size: 16px;
    height: 35px;
    min-height: 35px;
    min-width: 35px;
    width: 35px;
    background: var(--color-blue-700);
    color: #fff;
    border-radius: 50%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding-top: 2px;
    }
  }

  .button-infos {
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
      background: #fff;
      border-radius: 5px;
    }

    &:focus {
      outline: none;
    }
  }

  .delimiter {
    width: 60%;
  }

  .paperclip {
    margin-top: 5px;
  }

  button.see-file {
    margin-left: 5px;
    border: none;
    padding: 5px;
    border-radius: 8px;
    background: #F2F2F2;
    
    &:hover {
      background: #838383;
      color: #fff
    }
  }

  .axe-icon {
    height: 25px;
    width: 25px;
    padding-bottom: 5px;
    

    path {
      fill: #4d4f53;
    }
  }
}