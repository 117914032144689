.global-cotation {
  display: flex;
  gap: 25px;
  margin-left: 20px;
  align-items: center;

  .libelle {
      font-size: 20px;
      color: #D05C27;
      font-weight: 900;
  }

  .cotation-animator {
    margin-right: 20px;
    display: flex;
    align-items: center;
    gap: 5px;

    .title {
      font-size: 12px;
      color: var(--color-grey);
    }

    .cotation-container {
      display: flex;
      gap: 5px;
    }
  }

  .button-cotation {
      display: flex;
      gap: 5px;
      align-items: center;
      vertical-align: middle;
      border: none;
      background: transparent;

      &:disabled {
        cursor: not-allowed;
      }

      &:focus {
        outline: none;
      }

      .cotation {
          background-color: var(--color-light-grey);
          color: #fff;
          font-weight: 800;
          padding: 4px 8px 3px 8px;
          font-size: 12px;
          border-radius: 3px;
          display: flex;
          gap: 2px;
      }
  }

}