.action-plan {

  .axe-icon {
    height: 20px;
    width: 20px;

    path {
        fill: #747678;
    }
}

  .action-tab-button {
    text-transform: none !important;
    outline: none !important;
        &.Mui-selected {
          color: #e05206 !important;
          background: #FFF !important;
          font-weight: 700 !important;
          box-shadow: 0 0 10px rgb(217 217 217);

          .axe-icon {
            height: 20px;
            width: 20px;
    
            path {
                fill: #e05206;
            }
        }
        }
  }

  .action-plan-box {
    padding: 8px;
  }
  
  .action-tab-button:hover {
    color: #e05206;

    .axe-icon {
      height: 20px;
      width: 20px;

      path {
          fill: #e05206;
      }
  }
  }

  .action-tabs {
    & > .MuiTabs-scroller{
      overflow-x: auto !important;
    }
    .MuiTabs-indicator {
      display: flex;
      justify-content: center;
      background-color: transparent;
    }
    .MuiTabs-indicatorSpan {
      max-width: 40;
      width: 100%;
      background-color: transparent;
    }
    &:focus {
      outline: none;
    }
  }
  
  .action-panel-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background: #FFF !important;
    height: calc(100vh - var(--topbar-height) - 150px);
    box-shadow: 0 0 10px rgb(217 217 217);
    border: none;
  
    .suivi-select-contrib-label {
        font-style: italic;
    }
  }
  
  .action-sa-select {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem;
    height: 38px;
 
    > div {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
    .action-sa-select-item {
      .MuiOutlinedInput-root {
        border-radius: 20px !important;
        color: #747678 !important;
        box-shadow: 0 0 3px 2px #e1e1e1;
        width: 20rem;
      }
      .Mui-focused {
        border-color: #bdbdbd !important;
        border: none !important;
        outline: none !important;
      }
      .MuiButton-root {
        margin-left: 0.5rem;
        text-transform: none !important;
        background-color: #747678!important;
        color: #fff !important;
        border-radius: 20px !important;
      }
      .MuiButton-root:focus {
        outline: none !important;
      }
    }
  }
}

.action-btn-export {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  height: 40px !important;
  margin-right: 20px !important;

  .action-btn-export-text {
      padding-left: 5px;
      padding-top: 3px;
  }
}

