.configComplex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;

  .MuiOutlinedInput-root {
    border-radius: 10px !important;
    color: #747678 !important;
    box-shadow: 0 0 3px 2px #e1e1e1;
}
.Mui-focused {
    border-color: #bdbdbd !important;
    border: none !important;
    outline: none !important;
}

}

.searchContainer {
  width: 49.3%;
  border-radius: 10px;
  padding: 10px;

  .autocomplete-option {
    display: flex;
    align-items: center;
    gap: 30px;

    .title {
      color: var(--color-grey-800);
    }

    .option {
      font-size: 14px;
      color: #878e91;
      font-style: italic;
    }
  }
}
.search {
  width: 100%;
  color: #333;
  background: var(--color-grey-200);
  margin-top: 5px;
  border-radius: 10px;
}

.labelSearch {
  color: #333;
}