
#modal-duping {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: white;

  .close-modal {
    width: 40px;
    height: 40px;
  }

  .object-list {
    width: 100%;
    min-height: 50px;
    overflow-y: auto;

    .object-chip {
      color: rgba(0, 0, 0, 0.87);
      background-color: rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      font-size: 0.8125rem;
      height: 32px;
      margin: 3px;
      align-items: center;

      .delete-icon {
        -webkit-tap-highlight-color: transparent;
        color: rgba(0, 0, 0, 0.26);
        font-size: 22px;
        cursor: pointer;
        margin: 0 5px 0 5px;
      }

      .object-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}