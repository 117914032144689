.contributions {
  height: calc(80vh - var(--header-height));
  max-height: calc(80vh - var(--header-height));
  background-color: white;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .head {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 54px;
    position: relative;
    background-color: white;
    z-index: 5;

    h2 {
      font-size: 20px;
      font-weight: 800;
    }

    .selects {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }
  .component-select {
    .MuiOutlinedInput-root {
      border-radius: 20px;
      color: #747678;
      box-shadow: 0 0 3px 2px #e1e1e1;
      width: 17rem;
    }
    .Mui-focused {
        border-color: #bdbdbd;
        border: none;
        outline: none;
    }
    .MuiButton-root {
        margin-left: 0.5rem;
        text-transform: none;
        background-color: #747678;
        color: #fff;
        border-radius: 20px;
    }
    .MuiButton-root:focus {
        outline: none;
    }
  
    .select-wrapper {
      display: flex;
      gap: 5px;
      width: 30px;
      text-overflow: ellipsis;
      color: #747678;

      .placeholder {
        color: #bfc0c2;
      }
    }
  }
  

  .wrapper-table {
    padding: 0px 10px 10px 10px;
    height: 80vh;
    width: calc(100vw - 100px);
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

// Modal Action Supervision
.suppervision-modal {
    .custom-modal-content {
      margin-top: 10px;
      background-color: #F6F6F6;
      padding: 10px;

      .action {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .libelle {
          font-size: 16px;
          font-weight: 900;
        }

        .description {
          font-size: 14px;
          font-weight: 400;
          color: #656474;
          white-space: pre-line;
        }

        .chip {
          display: flex;
          gap: 50px;
          
          .item {
            display: flex;
            gap: 10px;
            align-items: center;
          }

          .circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-bottom: 5px;
          }
        }

        .date-and-user {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
    
          .user {
            display: flex;
            flex-direction: column;
    
            .pilote {
              display: flex;
              flex-direction: row;
              align-items: center;
    
              span {
                margin-right: 10px;
                padding: 10px;
                border-radius: 50%;
                min-width: 36px;
                text-align: center;
                color: white;
                font-size: 12px;
              }
    
              .name {
    
                .organisation {
                  font-style: italic;
                }
              }
            }
    
            .copilote {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 10px;
    
              span {
                margin-right: 10px;
                padding: 10px;
                min-width: 36px;
                text-align: center;
                border-radius: 50%;
                color: white;
                font-size: 12px;
              }
    
              .name {
                .organisation {
                  font-style: italic;
                }
              }
            }
          }
    
          .date {
            display: flex;
            flex-direction: column;
      
            .date-close {
              .operator {
                font-weight: 900;
                margin-right: 5px;
              }
              .title {
                font-weight: 900;
                color: #4d4f53;
                margin-right: 15px;
              }
              .item {
                padding: 5px;
                background: white;
                border-radius: 5px;
              }
            }
    
            .date-open {
              margin-bottom: 15px;
              .title {
                font-weight: 900;
                color: #4d4f53;
                margin-right: 5px;
              }
      
              .item {
                padding: 5px;
                background: white;
                border-radius: 5px;
              }
            }
          }
        }

        .progress-report {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .progress-item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .title {
              position: relative;
              display: flex;
              text-align: center;
              line-height: 2px;
              margin-top: 10px;
              margin-bottom: 10px;
              font-weight: 700;
              box-sizing: border-box;
              align-items: center;
              justify-content: center;

              background: linear-gradient(180deg, 
                rgba(0,0,0,0) calc(50% - 1px), 
                rgba(192,192,192,1) calc(50%), 
                rgba(0,0,0,0) calc(50% + 1px)
              );

              span {
                padding: 0 10px;
                background-color: var(--color-lighter-grey);
                text-transform: uppercase;
              }
            }

            .content {
              font-size: 15px;
              white-space: pre-line;
            }
          }
        }
      }
    }
  }