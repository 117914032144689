* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
}

#app {
  overflow: hidden;
  margin-top: var(--topbar-height)
}

body {
  min-height: auto !important;
}

h1 {
  font-weight: 400 !important;
  line-height: 1.25 !important;
  font-size: 1.5rem !important;
}

.confirmation-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #FFF;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modal-title {
    color: var(--primary);
    margin-top: 1rem;
  }
  .modal-warning {
    color: var(--primary);
  }
  .modal-description {
    margin-top: 1rem;
    text-align: center;
  }
  .modal-action-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    .MuiButton-contained {
      background-color: var(--color-blue-500);
      background-image: linear-gradient(to right,rgba(255,255,255,0),var(--color-blue-700) 100%);
      border-radius: 18px;
      margin-bottom: 0.5rem;

      &:hover {
        background-color: var(--color-blue-700);
      }

      &:focus {
        outline: none;
      }
    }
    .MuiButton-text {
      color: #747678;

    &:hover {
      background-color: white;
    }

    &:focus {
      outline: none;
    }
    }
  }
}

.vertical-display > div {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}

.vertical-display > input {
  width: 100% !important;
}

.bug-recover-button {
  font-size: 16px;
  color: var(--color-white);
  font-weight: 800;
  position: fixed;
  display: flex;
  bottom: 0px;
  right: 0px;
  transition: all .5s ease;
  background-color: #2877F9;
  border: none;
  letter-spacing: 0.16px;
  padding: 5px 10px;

  &:hover {
    background-color: #2a65a6;
  }

  &:focus {
    outline: none;
  }
}