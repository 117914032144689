
.component-tile {
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 15px 10px 10px 10px;


  .component-tile-expand {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-left: auto;
  }

  .component-tile-header {
    padding-bottom: 9px;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;

    .actions-recap {
      background: #F1F6F9;
      padding: 3px 4.5px;
      border-radius: 3px;
      width: fit-content;
      height: 25px;
      > span {
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
      }
    }
  }

  .component-item {
    width: 100%;
    min-width: 100%;
    border: none;
  
    .component-item-id {
      max-width: calc(100% - 100px);
      min-width: calc(100% - 100px);
      border: none;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 0;

      &:not(:disabled) {
        cursor: pointer;
        &:hover {
          background: var(--color-lighter-grey);
  
          .component-item-id-item {
            > span {
              background: unset !important;
            }
          }
        }
      }      
  
      .component-item-id-item {
        height: 40px;
        > span {
          text-align: center;
          padding: 2px;
          font-size: 25px;
          min-width: 25px;
          margin-right: 10px;
          font-weight: bold;
          border-radius: 5px;
          background: #F1F6F9;
          color: black;
        }
        
      }
  
      .component-item-libelle {
        height: 38px;
        max-width: calc(100% - 40px);
        min-width: calc(100% - 40px);
          > p {
            width: 100%;
            margin-top: 5px;
            font-size: 14px;
            color: #242830;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: start;
            line-height: 19.25px;
            max-height: 38.5px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
      }
    }
    .component-item-sa {
      height: 40px;
  
      .component-item-jalon {
        color: #242830;
        font-weight: 800;
        font-size: 17px;
      }
  
      .component-item-date {
        color: #A4A4A4;
        font-size: 17px;
      }
    }
  
    .component-item-cotation {
      color: #FFFFFF;
      
      .component-item-cotation-item {
        text-align: center;
        min-width: 30px;
        width: 30px;
        min-height: 30px;
        height: 30px;
        font-size: 15px;
        font-weight: 900;
        border-radius: 5px;
        padding: 5px;
      }
    }
  }
}


