
.contributors-add {
  margin: 1rem;
  width: 100%;

  .contributor-perimeter {
    display: flex;
    flex-direction: column;

    .perimeter-columns-container {
      display: flex;

      .perimeter-column-container {
        display: flex;
        flex-direction: column;
        flex: 1 1;
      }
    }
  }
}