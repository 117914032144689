.contribution-impersonate-avatar {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: 1px black;
  padding: 10px;
  background: var(--color-blue-700);
  color: #fff;
  border-radius: 50%;
}

.editor {
  font-size: 14px;
}
