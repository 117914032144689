.modal-wrapper {
  max-width: 800px;
  margin: auto;

  .feedback {
    margin-top: 20px;
    font-size: 14px;
    label {
      font-size: 14px;
    }

    p {
      color: var(--color-medium-grey);
    }

    .count {
      display: flex;
      justify-content: flex-end;
      margin-top: 1em;
      color: var(--color-medium-grey);

      div {
        margin-left: 5px;
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2em;
      font-size: 12px;
    }

    .feedback-warning {
      color: var(--color-red-600);
    }

    .content {
      text-align: center;
      font-size: 18px;
      color: var(--color-grey-800);
    }

    .button {
      display: flex;
      justify-content: center;
    }
  }
}
