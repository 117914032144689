.configListContainer {
  height: 300px;
  color: #333;
  overflow: auto;
  background: var(--color-grey-200);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  flex: 0 1 50%;
}

.configList_cont--item {
  height: 50px;
  background: var(--color-white);
  border-radius: 7px;
  font-size: 14px;
  padding: 5px;
  color: var(--color-grey-800);
  display: flex;
  align-items: center;

  .spanList {
    margin-left: 10px;
  }
}
