.supervision-portfolios {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--topbar-height));
  background-color: white;
  padding: 10px;
  gap: 20px;

  .title {
    font-size: 20px;
    font-weight: 800;
  }

  .accordion {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .content {
      width: 100%;
      display: flex;
      gap: 20px;
      margin-top: 20px;

      .portfolio-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        width: 50%;

        .MuiAutocomplete-popper {
          z-index: 9999;
        }
  
        .vertical-display {
          div {
            input {
              width: 100% !important;
            }
          }
        }
      }
    }
    .buttons {
      margin-top: 60px;
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        width: 200px;
        color: white;
        padding: 7px 20px 6px 20px;
        border-radius: 23px;
        font-weight: 500;
        font-size: 14px;
        transition: all .5s ease;

        &:hover {
          box-shadow: none;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      .delete {
        background-color: #fa7474;
        background-image: linear-gradient(to right,rgba(255,255,255,0),#ff4242 100%);

        &:hover {
          background-color: #ff4242;
        }
      }

      .modify {
        background-color: #0aa8f9;
        background-image: linear-gradient(to right,rgba(255,255,255,0),#2a65a6 100%);

        &:hover {
          background-color: #2a65a6;
        }
      }
    }
  }

  .importButton {
    display: flex;
    justify-content: center;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      width: 300px;
      color: white;
      padding: 7px 20px 6px 20px;
      border-radius: 23px;
      font-weight: 500;
      font-size: 14px;
      transition: all .5s ease;
      background-color: #0aa8f9;
      background-image: linear-gradient(to right,rgba(255,255,255,0),#2a65a6 100%);

      &:hover {
        box-shadow: none;
        background-color: #2a65a6;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.modal-import {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .input {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .button {
    display: flex;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      width: 300px;
      color: white;
      padding: 7px 20px 6px 20px;
      border-radius: 23px;
      font-weight: 500;
      font-size: 14px;
      transition: all .5s ease;
      background-color: #0aa8f9;
      background-image: linear-gradient(to right,rgba(255,255,255,0),#2a65a6 100%);

      &:hover {
        box-shadow: none;
        background-color: #2a65a6;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.map {
  position: relative;
  top: -45px;
  width: 50%;
  min-height: 500px;
}