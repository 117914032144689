.modal-admin {
  .modal-title {
    color: var(--color-warning-900);
  }
  .modal-info {
    color: var(--color-warning-900);
  }
  .modal-info--icon {
    font-size: 50px;
  }

  .search-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 40px;

    .search-gaia-ligne {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .spanModal {
    color: var(--color-blue-700);
  }
}

.search-icon {
  margin-right: 8px;
  color: #949598;
  cursor: text;
}
