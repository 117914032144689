
.contributor-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-height: 100vh;
  max-width: 1100px;
  background-color: #FFF;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 32px;
  overflow-y: auto;
  .MuiAutocomplete-root {
      height: 100%;
  }

  .contributor-modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .MuiTypography-root {
        display: flex;
        align-self: center;
        font-weight: 500 !important;
        font-size: 1.25rem !important;
    }
    .MuiIconButton-root {
        outline: none;
    }
  }

  .contributor-modal-body {
    display: flex;
    flex-direction: row;
    
    .contributor-modal-column {
      flex: 1 1;
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }

      .contributors-add {
        margin: 0;
      }

      .MuiAutocomplete-popper ul {
        max-height: 25vh;
      }
    }
  }
}