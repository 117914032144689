.portfolio {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .portfolio-container {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .portfolio-form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;
      width: 50%;

      .vertical-display {
        div {
          input {
            width: 100% !important;
          }
        }
      }
    }
    .map {
      width: 50%;
      height: 500px;
    }
  }
  
}