.tab-panel-suivi {
    width: calc(100% - 90px); 

    div[role=tabpanel] {
        .MuiBox-root {
            padding: 8px;
            background: #f2f2f2;
        }
    }
    
}

.suivi-sa-select {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem;
    height: 38px;

    .switch {
        margin-right: 50px;
    }
    .MuiOutlinedInput-root {
        border-radius: 20px !important;
        color: #747678 !important;
        box-shadow: 0 0 3px 2px #e1e1e1;
        width: 20rem;
    }
    .Mui-focused {
        border-color: #bdbdbd !important;
        border: none !important;
        outline: none !important;
    }
    .MuiButton-root {
        margin-left: 0.5rem;
        text-transform: none !important;
        background-color: #747678!important;
        color: #fff !important;
        border-radius: 20px !important;
    }
    .MuiButton-root:focus {
        outline: none !important;
    }
}

.suivi-jalon-side-tabs {
    transition: all 0.3s ease 0s;

    & > .MuiTabs-vertical {
        width: 90px;
    }
    .jalon-tab-button {
        outline: none !important;
        font-weight: 700 !important;
        opacity: 0.5;
        transition: all 0.3s ease 0s;
        &.Mui-selected {
            opacity: 1;
            box-shadow: 0 0 15px rgb(217 217 217);
            font-size: large;
        }
    }
}

.status {
    display: flex;
    justify-content: space-between;

    .suivi-btn-export {
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
        height: 40px !important;
        margin-right: 20px !important;

        .suivi-btn-export-text {
            padding-left: 5px;
            padding-top: 3px;
        }
    }

    .MuiTypography-root {
        font-size: 28px;
    }
    .MuiInputBase-root {
        width: 250px;
        height: 42px;
        border-radius: 20px !important;
        font-weight: 600 !important;
    }

    .switch .MuiTypography-root{
        font-size: 1em;
    }
}

.status-icon {
    margin-right: 8px;
    margin-bottom: 2px;
}

.date-previsionnelle {
    font-style: italic;
    font-size: .9rem;
    text-align: center;
    bottom: -1.5rem;
    height: 20px;
}

.suivi-tabs {

    & > .MuiTabs-scroller{
        overflow-x: auto !important;
    }
    .MuiTabs-indicator {
        display: flex;
        justify-content: center;
        background-color: transparent;
    }
    .MuiTabs-indicatorSpan {
    max-width: 40;
    width: 100%;
    background-color: transparent;
    }
    &:focus {
    outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    .axe-icon {
        height: 20px;
        width: 20px;

        path {
            fill: #747678;
        }
    }
}
  
.suivi-tab-button {
text-transform: none !important;
outline: none !important;
    &.Mui-selected {
        color: #e05206 !important;
        background: #FFF !important;
        font-weight: 700 !important;
        box-shadow: 0 0 10px rgb(217 217 217);

        .axe-icon {
            height: 20px;
            width: 20px;
    
            path {
                fill: #e05206;
            }
        }
    }
}
  
.suivi-tab-button:hover {
    color: #e05206;
    .axe-icon {
        height: 20px;
        width: 20px;

        path {
            fill: #e05206;
        }
    }
}
  
.suivi-panel-container {
    display: flex;
    flex-direction: column;
    padding: 3px;
    background: #FFF !important;
    height: calc(100vh - var(--topbar-height) - 220px);
    box-shadow: 0 0 10px rgb(217 217 217);
    border: none;

    .suivi-select-contrib-label {
        font-style: italic;
    }
}

.tile-container {
    padding: 0px 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, max-content));
    overflow-y: auto;
}

.select-contrib {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;

    .switch-contrib {
        margin-left: 20px;
    }

    .select-contrib-input {
        width: 300px;
        font-style: italic;
        margin-right: 20px;
    }
}

.type-tab {
    font-size: 25px;
}

.abrev-tab {
    padding-top: 10px;
}

.filter-avatar {
    font-size: 12px;
    border: 1px black;
    padding: 10px;
    background: var(--color-blue-700);
    color: #fff;
    border-radius: 50%;
}

.filter-name {
    font-size: 15px;
}

.arrayComponent-container {
    height: 50vh;
}