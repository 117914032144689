.tile {
  margin: 15px 15px;
  max-height: 130px;
  min-width: 325px;
  height: 100%;
  transition: all 0.3s ease 0s;
  padding-right: 10px;
}

.tile-isScroll {
  margin: 15px 15px;
  max-height: 130px;
  min-width: 320px;
  height: 100%;
  transition: all 0.3s ease 0s;
  padding-right: 10px;

  &:hover {
    scale: 1.1;
  }
}

.tile-link:hover {
  text-decoration: none;
}

.tile-card {
  background-color: #fff;
  max-width: 330px;
  height: 130px;
}

.tile-card-readOnly {
  background-color: #EEEEEE;
  max-width: 330px;
  height: 130px;
}

.tile:hover {
  scale: 1.1;
}

.card-orient {
  display: flex;
  flex-direction: row;
  height: 150px;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.87);

  .tile-section {
    display: flex;
    flex-direction: row;
    max-width: 265px;

    .tile-title {
      margin: 5px;
      margin-right: 10px;
      padding-right: 10px;
      .tile-title-section {
        display: flex;
        flex-direction: row;

        .tile-read-only {
          margin-left: 50px;
          font-style: italic;
        }
      }

      .title-title-p {
        font-size: 12px
      }
    }
  }


  .tile-section-2 {
    display: flex;
    flex-direction: column;
    margin: 10px 5px 10px 10px;
  }

  .card-orient-span {
    min-width: 10px;
    max-width: 10px;
    min-height: 100%;
  }

  .tile-header {
    width: 100%;
    display: flex;
    margin: 5px;
    justify-content: space-between;
  }

  .tile-header-contr {
    display: flex;
    flex-direction: row;
    background: var(--color-lighter-grey);
    border-radius: 10px;
    padding: 5px;
    font-size: 10px;

    span.tile-numerator, span.tile-denominator {
      color: black;
    }

    span.tile-denominator {
      font-weight: 600;
    }

  }

  .number-component {
    font-size: 20px;
  }

  .rating-container {
    margin-top: 20px;

    .contr-admin {
      position: relative;
      width: 45px;
      margin-top: -10px;
      margin-left: -30px;
      text-align: center;
      padding: 11px 13px;
      border-radius: 10px;
      color: var(--color-white);
    }

    .contr-admin-only {
      position: relative;
      width: 45px;
      margin-top: -10px;
      margin-left: -80px;
      text-align: center;
      padding: 11px 13px;
      border-radius: 10px;
      color: var(--color-white);
    }
    .contr {
      width: 45px;
      margin-top: 10px;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      color: var(--color-white);
    }
  }
}

.tile-a {
  height: auto;
}