.tabs-container {
  margin: 0 5px;
  min-height: calc(100vh - var(--topbar-height));
  display: flex;
  flex-direction: column;
}

.settings-tabs-box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.settings-tabs {
  .MuiTabs-indicator {
      display: flex;
      justify-content: center;
      background-color: transparent;
    }
    .MuiTabs-indicatorSpan {
      max-width: 40;
      width: 100%;
      background-color: transparent;
    }
    &:focus {
      outline: none;
    }
}

.settings-tab-button {
  text-transform: none !important;
  outline: none !important;
  &.Mui-selected {
      color: #e05206 !important;
      background: #FFF !important;
      font-weight: 700 !important;
      box-shadow: 0 0 10px rgb(217 217 217);
  }
}

.settings-tab-button:hover {
  color: #e05206;
}

.tab-panel-container {
  padding: 3px;
  background: #FFF;
  box-shadow: 0 0 10px rgb(217 217 217);
  border: none;
  flex: 1;
}

.selectSA {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin: 0.5rem;
  height: 38px;
  .MuiOutlinedInput-root {
      border-radius: 20px !important;
      color: #747678 !important;
      box-shadow: 0 0 3px 2px #e1e1e1;
      width: 20rem;
  }
  .Mui-focused {
      border-color: #bdbdbd !important;
      border: none !important;
      outline: none !important;
  }
  .MuiButton-root {
      margin-left: 0.5rem;
      text-transform: none !important;
      background-color: #747678!important;
      color: #fff !important;
      border-radius: 20px !important;
  }
  .MuiButton-root:focus {
      outline: none !important;
  }
}


.buttonValidateParam {
  margin-left: 20px;
  border: none;
  border-radius: 20px;
  color: var(--color-white);
  background: yellow;
  background-image: linear-gradient(to right,rgba(255,255,255,0),#e05206);
  padding: 5px 20px;

  .buttonValidateParam_span {
    margin-left: 10px;
  }
}

.buttonValidateParam:hover {
  background: #ffb612;
  background-image: linear-gradient(to right,rgba(255,255,255,0),#e05206);
}

.setting-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
}