.send-mail-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: #fff;
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 90vh;

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px
  }

  .modal-header {
    padding: 20px;

    .title-icon {
      font-size: 42px;
      color: var(--primary);
    }
  
    .title {
      font-size: 21px;
      color: var(--primary);
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 20px;
  }
  
  .send-mail-section-title {
    font-size: 20px;
    font-weight: 500;
  }

  .side-panel {
    flex: 1;
  }

  .role-select {
    width: 300px;
    cursor: default;
    
    .value-chip {
      background-color: rgb(235, 245, 255);
      border-radius: 3px;
      color: grey;
      align-items: center;
      width: fit-content;
      margin: 2px;

      .delete-icon {
        font-size: 10px;
        margin: 0 5px;
        cursor: pointer;
      }

      .value-label {
        text-align: center;
        padding: 0 5px;
        font-size: 12px;
        position: relative;

        &::after {
          content: ' ';
          position: absolute;
          left: 0px;
          top: 10%;
          height: 80%;
          border-left: 1px solid grey;
        }

      }
    }
  }

  .target-selector {
    height: 150px;
    overflow-y: auto;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;

    .pointer {
      font-weight: 400;
      font-size: 12px;
      cursor: pointer;
      text-decoration: underline;
    }

    .target-selector-item {
      margin-bottom: 0;
      align-items: flex-start;
      font-size: 12px;

      .MuiCheckbox-root {
        padding: 0 9px;
        font-size: 12px;

        svg {
          font-size: 1rem;
        }
      }

      span {
        font-size: 12px;
      }
    }
  }

  .mail-form {

    .mail-object-select {
      flex: 1;
      margin: 0;
      padding: 0;
    }

    .mail-form-field {
      background-color: #e1e1e1;
      border-radius: 4px;
      padding: 0.5rem 1rem;
    }

    .mail-form-actions {
      color: #fff;
      .btn-rounded.disabled {
        background-color: #f2f2f2 !important;
      }
      .btn-refresh {
        background-color: #0088ce;
        &:hover {
          background-color: #2a65a6;
        }
      }
      .btn-delete {
        background-color: #e81123;
        &:hover {
          background-color: #b80f1a;
        }
      }
      .btn-save {
        background-color: #82be00;
        &:hover {
          background-color: #5e8609;
        }
      }
    }
  }

  span.char-counter {
    font-size: 14px;
    color: grey;
    margin-right: 5px;

    &.warning {
      color: #e81123;
    }
  }
}

span.role-menu-item {
  text-transform: capitalize;
}