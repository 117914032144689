.settings-tab-container {
  height: inherit;

  .settings-tab-panel {
    flex: 0 1 50%;

    .settings-tab-form {
      margin: 1rem;
    }
  }

  ul.settings-tab-list {
    min-height: 225px;
    max-height: 95%;
    height: max-content;
    color: #333;
    overflow: auto;
    background: #f2f2f2;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    flex: 0 1 100%;
  
    li.settings-tab-list-item {
      width: auto;
      height: auto;
      padding: 1rem;
      margin-bottom: 0.5rem;
      background-color: white;
      border-radius: 5px;
  
      .settings-tab-list-item-container {
        display: flex;
        position: relative;
  
        .settings-tab-list-item-delete{
          border: none;
          background: rgba(255, 255, 255, 0);
          position: absolute;
          padding: 0;
          right: 0;
        }
    
        .item-name {
          padding-right: 1rem;
          border-right: 1px solid #d7d7d7;
          flex: 1 1 0px;

          .button-infos {
            display: flex;
            border: none;
            background: transparent;
            text-align: left;
    
            &:hover {
              background: #f2f2f2;
              border-radius: 5px;
            }
    
            &:focus {
              outline: none;
            }
          }
        }
    
        .item-function {
          padding-left: 1rem;
          flex: 1 1 0px;
        }
      }
    }
  }
  
  .no-item {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 1rem;
    font-style: italic;
    border-radius: 5px;
  }
}

