.configList_cont--item {
  background: var(--color-white);
  border-radius: 7px;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 8px;
  color: var(--color-grey-800);
  justify-content: space-between;
  height: 35px !important;

  .spanList {
    margin-left: 10px;
    display: flex;
    align-self: center;
    gap: 20px;

    .title {
      color: var(--color-grey-800);
    }

    .item {
      font-style: italic;
      
    }
  }

  .firstDiv {
    display: flex;
  }

  .buttonDeleteComplexeSa {
    border: none;
    background: rgba(255, 255, 255, 0);
  }

  
}

.popup-span {
    background-color: black;
    color: white;
    font-size: 0.7rem !important;
}

.configList_cont--item:hover {
  background: #E05206 !important;
  color: white !important;

  .spanList {
    color: white;

    .title {
      color: white;
    }
  }
}