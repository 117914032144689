.ponderation-container {
  display: flex;

  .ponderation {
    width: 70%;

    .bold {
      font-weight: 800;
      font-size: 14px;
    }

    .input-container {
      min-height: 60px;
      min-width: 80px;
    }
  }

  .cotation {
    width: 30%;
    margin-top: 5em;
    text-align: center;
    padding: 20px;

    .cotation-container {
      display: flex;
      justify-content: center;
      gap: 20px;
      max-width: 200px;
      margin: auto;
      margin-bottom: 10px;
      height: 60px;

      .pop-over-div {
        display: flex;
      }

      .cotation-item {
        padding: 7px 12px 3px 12px;
        font-size: 20px;
        font-weight: 800;
        background-color: #2c3e50;
        color: white;
        border-radius: 5px;
        height: 42px;
        width: 42px;
        
      }
    }
  }
}