.modal-info {
  .modal-info_title {
    color: var(--color-warning-900);
  }
  .modal-info_icon {
    font-size: 50px;
    color: var(--color-warning-900);
  }

  .modal-info_content {
    text-align: center;
    font-size: 18px;
    color: var(--color-grey-800)
  }

  .spanModal {
    color: var(--color-blue-700);
  }
}
